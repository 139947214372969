import type { ITransitionPage } from '@barba/core';
import gsap from 'gsap';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function fadeOut(el: Element): Promise<void> {
    return new Promise((resolve) => {
        gsap.to(el, { duration: 0.3, opacity: 0, onComplete: resolve });
    });
}

function timeout(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

function fadeIn(el: Element): Promise<void> {
    return new Promise((resolve) => {
        gsap.fromTo(el, { opacity: 0 }, { duration: 0.3, opacity: 1, onComplete: resolve });
    });
}

export default {
    leave({ current }): Promise<void> {
        return new Promise((resolve) => {
            const menu = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="menu"]');
            const transitionContainer = document.querySelector<HTMLElement>('.js-transition-page');
            const transitionInner = transitionContainer?.querySelector<HTMLElement>('.js-transition-page-inner');

            menu?.close();
            transitionContainer?.classList.add('is-show');

            const tl = gsap.timeline();
            if (transitionInner) {
                gsap.set(transitionInner, { opacity: 0, y: 40 });
                tl.fromTo(
                    transitionContainer,
                    {
                        duration: 1.5,
                        yPercent: -101,
                        xPercent: 101,
                        scale: 0.8,
                    },
                    {
                        yPercent: 0,
                        xPercent: 0,
                        scale: 1,
                    },
                    '+=0.5',
                )
                    .to(
                        transitionInner,
                        {
                            duration: 0.6,
                            opacity: 1,
                            y: 0,
                            ease: 'power3.in',
                        },
                        '-=0.3',
                    )
                    .add(resolve, 1.5);
            }
        });
    },

    enter({ next }): Promise<void> {
        return new Promise((resolve) => {
            const transitionContainer = document.querySelector<HTMLElement>('.js-transition-page');
            const transitionInner = transitionContainer?.querySelector<HTMLElement>('.js-transition-page-inner');

            window.scrollTo({ top: 0, behavior: 'auto' });

            const tl = gsap.timeline();
            if (transitionInner) {
                tl.to(
                    transitionInner,
                    {
                        duration: 0.6,
                        opacity: 0,
                        y: 10,
                        ease: 'none',
                    },
                    '+=0.7',
                )
                    .to(
                        transitionContainer,
                        {
                            duration: 0.8,
                            yPercent: -101,
                            xPercent: 101,
                            scale: 0.8,
                            onComplete: () => {
                                transitionContainer?.classList.remove('is-show');
                            },
                        },
                        '+=0.5',
                    )
                    .add(resolve, 0.3);
            }
        });
    },

    afterEnter() {
        // window.scrollTo({ top: 0, behavior: 'auto' });
    },
} as ITransitionPage;

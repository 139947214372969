import * as Cookies from 'js-cookie';
import { CookiesAgreement } from './CookiesAgreement/CookiesAgreement';
import { ImgSequence } from './ImgSequence/ImgSequence';
import { LitPopupElement } from './LitPopupElement/LitPopupElement';
import { YandexMap } from './YandexMap/YandexMap';
// import { WebShare } from './WebShare/WebShare';

export function initCustomElements() {
    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        customElements.define('app-cookies-agreement', CookiesAgreement);
    }

    if (window.matchMedia('(min-width: 1025px)').matches) {
        customElements.define('app-img-sequence', ImgSequence);
    }
    customElements.define('app-lit-popup', LitPopupElement);
    customElements.define('app-yandex-map', YandexMap);
    // customElements.define('app-web-share', WebShare);
}

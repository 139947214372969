// import gsap from 'gsap';
import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { IdleValue } from 'idlize/IdleValue.mjs';
import { lazyload, videoLazyload } from '../inits/lazy-load';
// import { createInitialInnerPageTimeline } from '../animations/initial-inner-page';
import { isMobileDevice } from '../utils/mobile';
import selects from '../inits/select';
import ajaxForms from '../inits/ajax-forms';
import headerTheme from '../inits/header-theme';
import floatedLabels from '../inits/floated-labels';
import inputMasks from '../inits/masks';
import adultPopup from '../inits/adult-popup';
import scrollto from '../inits/scrollto';
import drowLines from '../inits/drow-lines';
import reveal from '../animations/reveal';
// import videoSequence from '../inits/video-sequence';

const autoplayedVideosObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.target instanceof HTMLVideoElement) {
            if (entry.isIntersecting) {
                entry.target.play().catch(() => {});
            } else {
                entry.target.pause();
            }
        }
    });
});

export function initBaseView() {
    const beforeEnterQueue = new IdleQueue();
    let baseEnterTl: gsap.core.Timeline | null;
    let autoPlayedVideos: HTMLVideoElement[] = [];
    // let processSequenceComponent: any;

    function attemptToInitSelects() {
        if (isMobileDevice()) {
            selects.destroy();
        } else {
            selects.init();
        }
    }

    window.addEventListener('resize', attemptToInitSelects);

    barba.hooks.beforeEnter((data) => {
        // processSequenceComponent = videoSequence(data?.next.container);
        attemptToInitSelects();
        adultPopup.init();
        drowLines.init(data?.next.container);

        beforeEnterQueue.pushTask(() => {
            if (data) {
                autoPlayedVideos = Array.from(
                    data.next.container.querySelectorAll<HTMLVideoElement>('video[autoplay]'),
                );

                // autoPlayedVideos.forEach((video) => {
                //     video.play().catch(() => {});
                //     autoplayedVideosObserver.observe(video);
                // });
            }
        });

        beforeEnterQueue.pushTask(() => {
            floatedLabels.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            inputMasks.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            ajaxForms.init(data?.next.container);
        });
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
        videoLazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        headerTheme.init(data?.next.container);

        // beforeEnterQueue.pushTask(() => {
        //     if (data) {
        //         revealAnimations.init(
        //             data.next.container,
        //         );
        //     }
        // });
        reveal.init(data?.next.container);

        setTimeout(() => {
            scrollto.init(data?.next.container);
        }, 1);

        beforeEnterQueue.pushTask(() => {
            baseEnterTl?.getValue().play();
        });
    });

    barba.hooks.after((data) => {
        (window as any).ym?.(64705633, 'hit', window.location.pathname);
    });

    barba.hooks.beforeLeave((data) => {
        beforeEnterQueue.clearPendingTasks();

        autoPlayedVideos.forEach((video) => {
            autoplayedVideosObserver.unobserve(video);
        });
        autoPlayedVideos = [];

        ajaxForms.destroy(data?.current.container);
        inputMasks.destroy(data?.current.container);
        floatedLabels.destroy(data?.current.container);
        scrollto.destroy(data?.current.container);
        reveal.destroy(data?.current.container);
    });

    barba.hooks.afterLeave((data) => {
        selects.destroy(data?.current.container);
        headerTheme.destroy(data?.current.container);
        document.body.classList.remove('no-scroll');

        // if (processSequenceComponent) {
        //     processSequenceComponent.destroy();
        //     processSequenceComponent = null;
        // }
    });
}

import type { Swiper as SwiperType, SwiperOptions } from 'swiper';
import { fetchSwiper } from '../dynamic-modules';

export const map = new Map<Element, any>();

function generateNavButton(direction: 'prev' | 'next') {
    const button = document.createElement('button');
    button.className = `slider-nav-btn slider-nav-btn--${direction} js-slider-${direction}`;
    button.innerHTML = `
        <svg width="18" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m15.409 6.7 1.5-.02.04 3-1.5.02-.04-3ZM3.62 9.857l-1.5.02-.04-3 1.5-.02.04 3Zm3.765-7.961L8.489.88l2.03 2.208-1.104 1.015-2.03-2.208ZM2.571 8.357 1.556 9.462.355 8.357l1.201-1.104 1.015 1.104Zm6.844 4.253 1.105 1.015-2.03 2.209-1.105-1.015 2.03-2.21ZM15.45 9.7 3.62 9.856l-.04-3L15.409 6.7l.04 3ZM9.415 4.103 3.587 9.462l-2.03-2.21 5.828-5.356 2.03 2.208ZM3.587 7.253l5.828 5.357-2.03 2.209-5.829-5.357 2.03-2.21Z" fill="#fff"/></svg>
    `;
    button.setAttribute('aria-label', `${direction === 'prev' ? 'Предыдущий' : 'Следующий'} слайд`);
    return button;
}

export function createNav(swiper: SwiperType, sliderNav?: Element | null) {
    if (sliderNav) {
        const navigateBackwards = () => {
            swiper.slidePrev();
        };

        const navigateForwards = () => {
            swiper.slideNext();
        };

        const fragment = document.createDocumentFragment();
        const prevButton = generateNavButton('prev');
        fragment.appendChild(prevButton);
        prevButton.addEventListener('click', navigateBackwards);
        map.set(prevButton, navigateBackwards);

        const nextButton = generateNavButton('next');
        fragment.appendChild(nextButton);
        nextButton.addEventListener('click', navigateForwards);
        map.set(nextButton, navigateForwards);

        sliderNav.appendChild(fragment);
    }
}

export function destroyNav(containerEl?: Element | null) {
    if (containerEl) {
        const prevButton = containerEl.querySelector<HTMLElement>('.js-slider-prev');
        const nextButton = containerEl.querySelector<HTMLElement>('.js-slider-next');

        [prevButton, nextButton].forEach((btn) => {
            if (btn) {
                const listener = map.get(btn);

                if (listener) {
                    btn.removeEventListener('click', listener);
                    map.delete(btn);
                }
            }
        });
    }
}

// export function createCounter(swiper: SwiperType, container?: Element | null) {
//     if (container) {
//         const fragment = document.createDocumentFragment();
//         const current = document.createElement('div');
//         current.className = 'slider-counter--current';
//         current.textContent = withLeadingZero(swiper.realIndex + 1);
//         fragment.appendChild(current);

//         const total = document.createElement('div');
//         total.className = 'slider-counter--total';
//         total.textContent = withLeadingZero(
//             swiper.slides.filter((slide) => !slide.classList.contains('swiper-slide-duplicate')).length,
//         );
//         fragment.appendChild(total);

//         swiper.on('slideChange', () => {
//             current.textContent = withLeadingZero(swiper.realIndex + 1);
//         });

//         container.appendChild(fragment);
//     }
// }

import gsap from 'gsap';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
// import { SplitText } from 'gsap/SplitText';
import { fetchSwiper } from '../dynamic-modules';

const map = new WeakMap<Element, any>();
let popupEl: LitPopupElement | null;
let videoEl: HTMLVideoElement | null | undefined;

function onPopupOpen(event: any) {
    if (videoEl && event.detail.triggerElement?.dataset.videoSrc) {
        videoEl.src = event.detail.triggerElement.dataset.videoSrc;
        videoEl.play().catch(() => {});
    }
}

function onPopupClose() {
    if (videoEl) {
        gsap.to(videoEl, { duration: 0.3, volume: 0 });
    }
}

function onPopupCloseComplete() {
    if (videoEl) {
        videoEl.src = '';
    }
}

function init(container: HTMLElement | Document = document) {
    popupEl = container.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="video"]');

    if (popupEl) {
        videoEl = popupEl.instance.innerContainer?.querySelector<HTMLVideoElement>('video.js-video-popup-el');
        popupEl.addEventListener('open', onPopupOpen);
        popupEl.addEventListener('close', onPopupClose);
        popupEl.addEventListener('close-complete', onPopupCloseComplete);
    }

    Array.from(container.querySelectorAll<HTMLElement>('.js-videos-slider:not(.swiper-initialized)')).forEach(
        async (el) => {
            const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

            if (slides.length > 1) {
                const { Swiper, Pagination } = await fetchSwiper();

                function playSlide(slide: Element, action: 'enter' | 'leave') {
                    // if (matchMedia('(max-width: 767px), (max-width: 815px) and (orientation: landscape)').matches) {
                    //     return;
                    // }
                    // const title = slide.querySelector<HTMLElement>('.js-video-card-title');
                    // const description = slide.querySelector<HTMLElement>('.js-video-card-description');
                    // if (title) {
                    //     const titleTween = titlesTweensMap.get(title);
                    //     if (titleTween) {
                    //         if (action === 'enter') {
                    //             titleTween.restart();
                    //         } else {
                    //             titleTween.reverse();
                    //         }
                    //     }
                    // }
                    // if (description) {
                    //     const descriptionTween = descriptionsTweensMap.get(description);
                    //     if (descriptionTween) {
                    //         if (action === 'enter') {
                    //             descriptionTween.restart();
                    //         } else {
                    //             descriptionTween.reverse();
                    //         }
                    //     }
                    // }
                }

                const slider = new Swiper(el, {
                    modules: [Pagination],
                    slidesPerView: 1,
                    watchSlidesProgress: true,
                    virtualTranslate: true,
                    rewind: true,
                    followFinger: false,
                    threshold: 30,
                    touchAngle: 65,
                    pagination: {
                        el: '.js-slider-pagination',
                        type: 'bullets',
                        clickable: true,
                        renderBullet: (index, className) =>
                            `<button class="${className}" aria-label="Перейти на слайд ${index + 1}"></button>`,
                    },
                    speed: 0,
                    on: {
                        init: (swiper) => {
                            // if (
                            //     !matchMedia(
                            //         '(max-width: 767px), (max-width: 815px) and (orientation: landscape)',
                            //     ).matches
                            // ) {
                            //     const titles = swiper.slides.map((el) =>
                            //         el.querySelector<HTMLElement>('.js-news-card-title'),
                            //     );
                            //     const descriptions = swiper.slides.map((el) =>
                            //         el.querySelector<HTMLElement>('.js-news-card-description'),
                            //     );
                            //     titles.forEach((_el) => {
                            //         if (_el) {
                            //             const splitted = new SplitText(_el, { type: 'lines' });
                            //             const tween = gsap.fromTo(
                            //                 splitted.lines,
                            //                 { opacity: 0, yPercent: 100 },
                            //                 {
                            //                     yPercent: 0,
                            //                     opacity: 1,
                            //                     duration: 0.5,
                            //                     stagger: 0.05,
                            //                     ease: ease1,
                            //                     paused: true,
                            //                 },
                            //             );
                            //             titlesTweensMap.set(_el, tween);
                            //         }
                            //     });
                            //     descriptions.forEach((_el) => {
                            //         if (_el) {
                            //             const splitted = new SplitText(_el, { type: 'lines' });
                            //             const tween = gsap.fromTo(
                            //                 splitted.lines,
                            //                 { opacity: 0, yPercent: 100 },
                            //                 {
                            //                     yPercent: 0,
                            //                     opacity: 1,
                            //                     duration: 0.5,
                            //                     stagger: 0.05,
                            //                     ease: ease1,
                            //                     paused: true,
                            //                 },
                            //             );
                            //             descriptionsTweensMap.set(_el, tween);
                            //         }
                            //     });
                            // }
                        },
                        afterInit: (swiper) => {
                            // playSlide(slides[swiper.activeIndex], 'enter');
                        },
                        slideChange: (swiper) => {
                            // clearTimeout(timer);
                            // playSlide(slides[swiper.previousIndex], 'leave');
                            // timer = setTimeout(() => {
                            //     playSlide(slides[swiper.activeIndex], 'enter');
                            // }, 300);
                        },
                    },
                });
                map.set(el, slider);
            }
        },
    );
}

function destroy(container: HTMLElement | Document = document) {
    if (popupEl) {
        popupEl.removeEventListener('open', onPopupOpen);
        popupEl.removeEventListener('close', onPopupClose);
        popupEl.removeEventListener('close-complete', onPopupCloseComplete);
        popupEl = null;
    }

    Array.from(container.querySelectorAll<HTMLElement>('.js-videos-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;

import gsap from 'gsap';
import { CountUp } from 'countup.js';
import barba from '@barba/core';
import { timeout } from '../utils/timeout';
import { ImgSequence } from '../custom-elements/ImgSequence/ImgSequence';

export const preloaderAnimationDuration = 0.7; // sec
let lengthAssets = 0;
let countAssets = 0;

function init() {
    function createPreloader() {
        const preloader = document.querySelector<HTMLElement>('.js-preloader');
        const preloaderInner = document.querySelector<HTMLElement>('.js-preloader-inner');
        const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
        let loaded = false;

        const state = {
            completed: false,
        };

        const counterInstance = counter
            ? new CountUp(counter, 0, {
                  startVal: 0,
                  useEasing: true,
                  duration: 1.05,
              })
            : null;

        console.log(counterInstance);

        document.body.classList.add('no-scroll');

        function leave(): Promise<void> {
            return new Promise((resolve) => {
                requestAnimationFrame(() => {
                    document.body.classList.remove('no-scroll');
                });

                document.dispatchEvent(new Event('preloader-leave'));

                barba.hooks.afterEnter(() => {
                    if (!loaded) {
                        loaded = true;
                    }
                });

                const tl = gsap.timeline();

                tl.to(
                    preloaderInner,
                    {
                        duration: 0.3,
                        opacity: 0,
                    },
                    0.2,
                )
                    .to(preloader, {
                        duration: 0.8,
                        yPercent: -101,
                        xPercent: 101,
                        scale: 0.8,
                        onComplete: () => {
                            preloader?.classList.add('is-hide');
                            state.completed = true;
                        },
                    })
                    .add(resolve, 0.65);
            });
        }

        function loadAsset(asset: HTMLImageElement | HTMLVideoElement, isMainPage: boolean): Promise<void> {
            return new Promise((resolve) => {
                countAssets++;
                let percentProgress = Math.floor((countAssets / lengthAssets) * 100);
                setPercent(percentProgress);

                if (asset instanceof HTMLImageElement) {
                    if (asset.complete) {
                        resolve();
                    } else {
                        asset.onload = () => resolve();
                        asset.onerror = () => resolve();
                    }
                }

                if (asset instanceof HTMLVideoElement) {
                    if (asset.readyState === 4) {
                        resolve();
                    } else {
                        asset.addEventListener('canplaythrough', () => resolve(), { once: true });
                    }
                }
            });
        }

        function setPercent(value: number) {
            counterInstance?.update(value);
        }

        async function loadAssetsFromElement(element: Element | Document = document) {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'),
            );
            const videos = Array.from(
                element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not(.video-lazy):not([loading="lazy"])'),
            );
            const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];
            console.log(assets);

            const isMainPage = window.location.pathname == '/';
            lengthAssets = images.length;

            if (isMainPage) {
                const waitForSequenceFramesToLoad = () =>
                    new Promise((resolve) => {
                        document.addEventListener('sequence-img-loaded', function onSequenceImgLoaded(event) {
                            countAssets++;
                            const percent = Math.round((event.detail.loaded / event.detail.total) * 100);
                            setPercent(percent);

                            if (percent >= 100) {
                                document.removeEventListener('sequence-img-loaded', onSequenceImgLoaded);
                            }
                        });
                        document.addEventListener('sequence-loaded', resolve, { once: true });
                    });

                await waitForSequenceFramesToLoad();
            } else {
                if (images.length > 0) {
                    await Promise.all<any>(assets.map((asset) => loadAsset(asset, isMainPage)));
                } else {
                    setPercent(100);
                }
            }
        }

        async function loadAssets() {
            await loadAssetsFromElement(document.body);

            // const mainSequence = document.querySelector<ImgSequence>('app-img-sequence.js-main-screen-sequence');
            // if (mainSequence && window.matchMedia('(min-width: 1025px)').matches) {
            //     // await isSequence(mainSequence);
            // }
        }

        // async function isSequence(mainSequence: ImgSequence) {
        //     return new Promise<any>(resolve =>  mainSequence.addEventListener('ready', () => {
        //         setPercent(100);
        //         resolve();
        //     }));
        // }

        return { leave, loadAssets, state } as const;
    }

    const preloaderInstance = createPreloader();

    // Initial load
    preloaderInstance
        .loadAssets()
        .then(() => timeout(1700))
        .then(() => preloaderInstance.leave());
    // .then(() => {});
}

export default { init };

export const sequenceState: { frameCount: number; images: HTMLImageElement[] } = {
    frameCount: 179,
    images: [],
};

const format = document.documentElement.classList.contains('avif') ? 'avif' : 'webp';

export const downloadSequence = (container: HTMLElement) => {
    let loaded = 0;
    const isMobile = matchMedia('(max-width: 1024px) and (orientation: portrait)').matches;
    const country = container.dataset.country && container.dataset.country.trim() === 'ru' ? 'ru' : 'kz';
    // const country = 'kz';
    console.log({ country });
    const device = isMobile ? 'mobile' : 'desktop';
    const currentFrame = (index: number) =>
        `/static/img/${country}-${device}/img${(index + 1).toString().padStart(3, '0')}.${format}`;

    for (let i = 0; i < sequenceState.frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        img.addEventListener(
            'load',
            () => {
                loaded++;

                document.dispatchEvent(
                    new CustomEvent('sequence-img-loaded', {
                        detail: { loaded, total: Math.round(sequenceState.frameCount / 2) },
                    }),
                );

                if (loaded === Math.round(sequenceState.frameCount / 2)) {
                    document.dispatchEvent(new Event('sequence-loaded'));
                }
            },
            { once: true },
        );
        sequenceState.images[i] = img;
    }
};

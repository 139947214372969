import gsap from 'gsap';
import debounce from 'lodash.debounce';
import { downloadSequence, sequenceState } from './sequence';

let sequenceContainer: HTMLElement | null;
let canvas: HTMLCanvasElement | null;
let context: CanvasRenderingContext2D | null;
const seq = { frame: 0 };
let prevFrame = -1;

function render(force = false) {
    if (canvas && context && sequenceState.images[seq.frame] && (force ? true : seq.frame !== prevFrame)) {
        const isMobile = matchMedia('(max-width: 1024px) and (orientation: portrait)').matches;

        const imgWidth = isMobile ? 1080 : 1920;
        const imgHeight = 1080;
        const imgAspectRatio = imgWidth / imgHeight;
        const canvasAspectRatio = canvas.width / canvas.height;
        let x = 0;
        let y = 0;
        let width = canvas.width;
        let height = canvas.height;

        if (isMobile) {
            if (imgAspectRatio > canvasAspectRatio) {
                height = width / imgAspectRatio;
                y = (canvas.height - height) / 2;
            } else {
                width = height * imgAspectRatio;
                x = (canvas.width - width) / 2;
            }
        } else {
            if (imgAspectRatio < canvasAspectRatio) {
                height = width / imgAspectRatio;
                y = (canvas.height - height) / 2;
            } else {
                width = height * imgAspectRatio;
                x = (canvas.width - width) / 2;
            }
        }

        let mult = 1;

        if (matchMedia('(max-width: 767px) and (orientation: portrait)').matches) {
            mult = 2;
        } else if (isMobile) {
            mult = 1.5;
        }

        // console.time('1');
        context.clearRect(x - (width / 2) * (mult - 1), y - (height / 2) * (mult - 1), width * mult, height * mult);
        context.drawImage(
            sequenceState.images[seq.frame],
            x - (width / 2) * (mult - 1),
            y - (height / 2) * (mult - 1),
            width * mult,
            height * mult,
        );
        // console.timeEnd('1');

        if (sequenceState.images[seq.frame].complete) {
            prevFrame = seq.frame;
        }
    }
}

function setCanvasDimensions() {
    const dpr = Math.min(window.devicePixelRatio, 2);
    if (canvas) {
        canvas.width = canvas.offsetWidth * dpr;
        canvas.height = canvas.offsetHeight * dpr;
        render(true);
    }
}

const onSequenceLoaded = () => {
    render(true);
};

function onResize() {
    setCanvasDimensions();

    if (sequenceContainer) {
        downloadSequence(sequenceContainer);
    }
}

const debouncedOnResize = debounce(onResize, 100);

function init(container: HTMLElement | Document = document) {
    const _container = container.querySelector<HTMLElement>('.js-first-section');
    sequenceContainer = container.querySelector<HTMLElement>('.js-sequence-container');
    const textContent = container.querySelectorAll<HTMLElement>('.js-first-block-content');
    canvas = container.querySelector<HTMLCanvasElement>('canvas#v0');

    if (canvas) {
        context = canvas.getContext('2d');
        onResize();

        function hideContent(previousIndex: number, activeIndex: number, delay = 300) {
            textContent[previousIndex]?.classList.add('is-hide');

            setTimeout(() => {
                textContent[previousIndex]?.classList.remove('is-show');
                textContent[previousIndex]?.classList.add('is-hide');
                textContent[activeIndex]?.classList.remove('is-hide');
                textContent[activeIndex]?.classList.add('is-show');
            }, delay);
        }

        let activeText = 0;

        gsap.to(seq, {
            frame: sequenceState.frameCount - 1,
            snap: 'frame',
            ease: 'none',
            scrollTrigger: {
                trigger: _container,
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
                onUpdate: ({ progress }) => {
                    const isMobile = matchMedia('(max-width: 1024px)').matches;

                    if (isMobile) {
                        if (progress <= 0.3) {
                            if (activeText !== 0) {
                                hideContent(activeText, 0, 0);
                                activeText = 0;
                            }
                        } else if (progress > 0.5 && progress <= 0.75) {
                            if (activeText !== 1) {
                                hideContent(activeText, 1, 0);
                                activeText = 1;
                            }
                        } else if (progress > 0.87 && progress <= 0.98) {
                            if (activeText !== 2) {
                                hideContent(activeText, 2, 0);
                                activeText = 2;
                            }
                        } else {
                            if (activeText !== 3) {
                                hideContent(activeText, 3);
                                activeText = 3;
                            }
                        }
                    } else {
                        if (progress < 0.16) {
                            if (activeText !== 0) {
                                hideContent(activeText, 0);
                                activeText = 0;
                            }
                        } else if (progress >= 0.16 && progress <= 0.6) {
                            if (activeText !== 1) {
                                hideContent(activeText, 1);
                                activeText = 1;
                            }
                        } else if (progress > 0.6) {
                            if (activeText !== 2) {
                                hideContent(activeText, 2);
                                activeText = 2;
                            }
                        }
                    }
                },
            },
            onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
        });

        if (sequenceState.images[0]) {
            if (sequenceState.images[0].complete) {
                render();
            } else {
                sequenceState.images[0].onload = () => render();
            }
        }
    }

    document.addEventListener('sequence-loaded', onSequenceLoaded);
    window.addEventListener('resize', debouncedOnResize);
}

function destroy(container: HTMLElement | Document = document) {
    document.removeEventListener('sequence-loaded', onSequenceLoaded);
    window.removeEventListener('resize', debouncedOnResize);
}

const _module = { init, destroy };

export default _module;

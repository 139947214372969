import barba from '@barba/core';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const popupName = 'menu';
const menuPopup = document.querySelector<LitPopupElement>(`app-lit-popup[data-lit-popup="${popupName}"]`);
let openTimer: NodeJS.Timeout;
let closeTimer: NodeJS.Timeout;

function onOpen() {
    clearTimeout(openTimer);
    const menuLinks = document.querySelectorAll<HTMLLinkElement>('a.js-menu-link');
    const locationHref = document.location.href;

    if (locationHref && menuLinks) {
        for (let i = 0; i < menuLinks.length; i++) {
            if (locationHref === menuLinks[i].href) {
                menuLinks[i].classList.add('is-active');
            } else {
                menuLinks[i].classList.remove('is-active');
            }
        };
    }

    openTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-menu-open-btn')).forEach((el) => {
            // el.classList.add('is-opened');
            el.removeAttribute('data-lit-popup-open');
            el.setAttribute('data-lit-popup-close', popupName);
        });
    }, 1);
}

function onClose() {
    clearTimeout(closeTimer);
    document.body.classList.remove('menu-opened');

    closeTimer = setTimeout(() => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-menu-open-btn')).forEach((el) => {
            el.removeAttribute('data-lit-popup-close');
            el.setAttribute('data-lit-popup-open', popupName);
            // el.classList.remove('is-opened', 'focus-visible');
        });
    }, 1);
}

function init() {
    if (menuPopup) {
        menuPopup.instance.on('open', onOpen);
        menuPopup.instance.on('close', onClose);

        barba.hooks.enter(() => {
            menuPopup.close();
        });
    }
}

function destroy() {
    clearTimeout(openTimer);
    clearTimeout(closeTimer);

    if (menuPopup) {
        menuPopup.instance.off('open', onOpen);
        menuPopup.instance.off('close', onClose);
    }
}

const _module = { init, destroy };

export default _module;

import gsap from 'gsap';
import barba from '@barba/core';
import { getOffsetTop } from '../utils/dom';
import { fetchScrollToPlugin } from '../dynamic-modules';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const SELECTOR = '[data-scrollto]';

const header = document.querySelector('.js-header');
let elements: HTMLElement[] = [];

export async function scrollTo(el: Element) {
    const y = getOffsetTop(el) - (header?.getBoundingClientRect().height || 0) - 20;
    const { ScrollToPlugin } = await fetchScrollToPlugin();
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, { duration: 0.7, scrollTo: y, ease: 'power2.inOut' });
}

async function onClick(this: HTMLElement, event: Event) {
    event.preventDefault();
    const selector = this.dataset.scrollto;

    if (!selector) {
        throw new Error(`[scrollto] ${SELECTOR} is missing on the element.`);
    }

    const menu = this.closest<LitPopupElement>('[data-lit-popup="menu"]');
    if (menu) menu.close();

    const elementScrollTo = document.querySelector(selector);

    if (elementScrollTo) {
        scrollTo(elementScrollTo);
    } else if (this instanceof HTMLAnchorElement && this.href) {
        // window.location.href = this.href;
        barba.go(this.href);
    }
}

function init(container: HTMLElement | Document = document) {
    elements = Array.from(document.querySelectorAll<HTMLElement>(SELECTOR));

    elements.forEach((el) => {
        el.addEventListener('click', onClick);
    });

    if (window.location.hash) {
        const elementScrollTo = container.querySelector(window.location.hash);

        if (elementScrollTo) {
            scrollTo(elementScrollTo);
        }
    }
}

function destroy(container: HTMLElement | Document = document) {
    elements = Array.from(document.querySelectorAll<HTMLElement>(SELECTOR));
    elements.forEach((el) => {
        el.removeEventListener('click', onClick);
    });

    elements = [];
}

const _module = { init, destroy };

export default _module;

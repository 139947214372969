import type { IView } from '@barba/core';
import gsap from 'gsap';
// import { CustomEase } from 'gsap/CustomEase';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
// import { IdleValue } from 'idlize/IdleValue.mjs';
// import videoSequence from '../inits/video-sequence';
import indexSequence from '../inits/index-sequence';
import flavoursSlider from '../inits/flavours-sliders';
import aromaSlider from '../inits/aroma-sliders';
import aromaCardHovers from '../inits/aroma-card-hovers';
import videosSlider from '../inits/videos-sliders';
import { spline } from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';
import parallaxBlock from '../inits/parallax-block';
import arrowsSlider from '../inits/arrows-slider';

var isChrome = navigator.userAgent.indexOf('Chrome') > -1;
let isSafari = navigator.userAgent.indexOf('Safari') > -1;

if (isChrome && isSafari) {
    isSafari = false;
}

const beforeEnterQueue = new IdleQueue();
let enterTl: any;
let exhibitionImg: HTMLElement | null;
// let firstSectionMaskImg: HTMLElement | null;
let path: SVGPathElement | null;
let morphExhibitionImgFn: gsap.TickerCallback | null;

const exhibitionMorphObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (path && morphExhibitionImgFn) {
            if (entry.isIntersecting) {
                gsap.ticker.add(morphExhibitionImgFn);
            } else {
                gsap.ticker.remove(morphExhibitionImgFn);
            }
        }
    });
});

function map(n: number, start1: number, end1: number, start2: number, end2: number) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

function createPoints(radius = 75) {
    const points = [];
    // how many points do we need
    const numPoints = 7;
    // used to equally space each point around the circle
    const angleStep = (Math.PI * 2) / numPoints;
    // the radius of the circle

    for (let i = 1; i <= numPoints; i++) {
        // x & y coordinates of the current point
        const theta = i * angleStep;

        const x = 100 + Math.cos(theta) * radius;
        const y = 100 + Math.sin(theta) * radius;

        // store the point
        points.push({
            x: x,
            y: y,
            /* we need to keep a reference to the point's original {x, y} coordinates
        for when we modulate the values later */
            originX: x,
            originY: y,
            // more on this in a moment!
            noiseOffsetX: Math.random() * 1000,
            noiseOffsetY: Math.random() * 1000,
        });
    }

    return points;
}

let processSequenceComponent: any;

export default {
    namespace: 'index-page',

    beforeEnter({ next }) {
        beforeEnterQueue.pushTask(() => {
            exhibitionImg = next.container.querySelector<HTMLElement>('.js-exhibition-img');
            path = document.querySelector<SVGPathElement>('path#path-1');

            if (exhibitionImg && path) {
                const simplex = new SimplexNoise();
                const state = { noiseStep: 0.002 };
                const points = createPoints();

                gsap.to(state, { duration: 2, noiseStep: 0.003, yoyo: true, repeat: -1 });

                // function redrawClipPath() {
                //     if (exhibitionImg) {
                //         exhibitionImg.style.clipPath = 'none';
                //         exhibitionImg.offsetWidth; // force a style recalculation
                //         exhibitionImg.style.clipPath = 'url(#svgPath)';
                //     }
                // }

                function animate() {
                    path?.setAttribute('d', spline(points, 1, true));

                    // if (isSafari) {
                    //     redrawClipPath();
                    // }

                    for (let i = 0; i < points.length; i++) {
                        const point = points[i];
                        // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
                        // const nX = simplex.noise2D(point.noiseOffsetX, point.noiseOffsetX);
                        // const nY = simplex.noise2D(point.noiseOffsetY, point.noiseOffsetY);
                        const nX = simplex.noise2D(point.noiseOffsetX, point.noiseOffsetX);
                        const nY = simplex.noise2D(point.noiseOffsetY, point.noiseOffsetY);
                        // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
                        const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
                        const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);
                        // update the point's current coordinates
                        point.x = x;
                        point.y = y;
                        // progress the point's x, y values through "time"
                        point.noiseOffsetX += state.noiseStep;
                        point.noiseOffsetY += state.noiseStep;
                    }
                }

                morphExhibitionImgFn = animate;
                exhibitionMorphObserver.observe(exhibitionImg);
                exhibitionImg.classList.add('is-initialized');
            }

            // if (firstSectionMaskImg) {
            //     gsap.to(firstSectionMaskImg, {
            //         duration: 1.3,
            //         '--mask-size': 1000,
            //         ease: 'power2.in',
            //         yoyo: true,
            //         repeat: -1,
            //     });
            // }
        });

        beforeEnterQueue.pushTask(() => {
            flavoursSlider.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            aromaSlider.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            videosSlider.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            aromaCardHovers.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            parallaxBlock.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            arrowsSlider.init(next.container);
        });
    },

    afterEnter({ next }) {
        beforeEnterQueue.pushTask(() => {
            // processSequenceComponent = videoSequence(next.container);
            indexSequence.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            enterTl?.play();
        });
    },

    beforeLeave({ current }) {
        beforeEnterQueue.clearPendingTasks();
        indexSequence.destroy(current.container);

        if (exhibitionImg) {
            exhibitionMorphObserver.unobserve(exhibitionImg);
            exhibitionImg = null;
        }

        morphExhibitionImgFn = null;
        path = null;

        if (enterTl) {
            enterTl.kill();
            enterTl = null;
        }

        aromaCardHovers.destroy(current.container);
    },

    afterLeave({ current }) {
        flavoursSlider.destroy(current.container);
        aromaSlider.destroy(current.container);
        videosSlider.destroy(current.container);
        parallaxBlock.destroy(current.container);
        arrowsSlider.destroy(current.container);

        if (processSequenceComponent) {
            processSequenceComponent.destroy();
            processSequenceComponent = null;
        }
    },
} as IView;

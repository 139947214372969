import Cookies from 'js-cookie';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import axios from 'axios';

const COOKIE_NAME = 'LOCATION_COUNTRY_ID';

function init() {
    if (!Cookies.get(COOKIE_NAME)) {
        const langSelect = document.querySelector<HTMLSelectElement>('select.js-lang-switcher');
        const geotargetingPrompt = document.querySelector<HTMLElement>('.js-geotarteting-prompt');
        const geotargetingPromptAcceptBtn = document.querySelector<HTMLElement>('.js-geotarteting-prompt-accept');
        const geotargetingPromptDenyBtn = document.querySelector<HTMLElement>('.js-geotarteting-prompt-deny');
        const geotargetingPopupEl = document.querySelector<LitPopupElement>('[data-lit-popup="geotargeting"]');
        const countryButtons = Array.from(document.querySelectorAll<HTMLElement>('.js-geotargeting-country-btn'));

        const showPrompt = () => {
            geotargetingPrompt?.classList.add('is-visible');

            if (matchMedia('(max-width: 767px), (max-width: 900px) and (orientation: landscape)').matches) {
                document.body.classList.add('no-scroll');
            }
        };

        const hidePrompt = () => {
            geotargetingPrompt?.classList.remove('is-visible');

            if (matchMedia('(max-width: 767px), (max-width: 900px) and (orientation: landscape)').matches) {
                document.body.classList.remove('no-scroll');
            }
        };

        const setCountry = async (url: string | undefined) => {
            // Cookies.set(COOKIE_NAME, `${id}`, { expires: 365 /* days */, path: '/' });
            if (url) {
                axios
                    .get(url, {})
                    .then(function (response) {
                        if (response.data.data.url) {
                            window.location.href = response.data.data.url;
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.config);
                        }
                    });
            }
        };

        const selectLang = async (event: any) => {
            const { url } = event.target.dataset;
            if (url) {
                const fullUrl = url + event.target.value;
                setCountry(fullUrl);
            }
        };

        function accept(this: HTMLElement) {
            const { url } = this.dataset;
            setCountry(url);
            hidePrompt();
        }

        const deny = () => {
            hidePrompt();
            geotargetingPopupEl?.open();
        };

        function onCountryBtnClick(this: HTMLElement) {
            const { countryId, url } = this.dataset;
            if (countryId && url) {
                const fullUrl = url + countryId;
                setCountry(fullUrl);
            }
        }

        langSelect?.addEventListener('change', selectLang);
        geotargetingPromptAcceptBtn?.addEventListener('click', accept);
        geotargetingPromptDenyBtn?.addEventListener('click', deny);
        countryButtons.forEach((btn) => {
            btn.addEventListener('click', onCountryBtnClick);
        });

        document.addEventListener('preloader-leave', showPrompt, { once: true });
    }
}

const _module = { init };

export default _module;

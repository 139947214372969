import './webpack-imports';
import { applyPolyfills } from './polyfills';
import { createGrid } from './grid';
import './sentry';
import './custom-elements';
import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { initCustomElements } from './custom-elements';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import BaseTransition from './transitions/base';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import menu from './inits/menu';
import ajaxForms from './inits/ajax-forms';
import floatedLabels from './inits/floated-labels';
import inputMasks from './inits/masks';
import geotargeting from './inits/geotargeting';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';
import preloader from './components/preloader';
import IndexPageView from './views/index';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin, DrawSVGPlugin, CustomEase);

    initCustomElements();
    initBaseView();

    const appQueue = new IdleQueue();
    const contactUsPopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="contact-us"]');
    const eventPopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="event-popup"]');

    appQueue.pushTask(calculateScrollbarWidth);
    appQueue.pushTask(() => menu.init());
    appQueue.pushTask(() => geotargeting.init());
    appQueue.pushTask(() => {
        if (contactUsPopupEl) {
            ajaxForms.init(contactUsPopupEl);
            floatedLabels.init(contactUsPopupEl);
            inputMasks.init(contactUsPopupEl);
        }

        if (eventPopupEl) {
            ajaxForms.init(eventPopupEl);
            floatedLabels.init(eventPopupEl);
            inputMasks.init(eventPopupEl);
        }
    });

    // Initial load
    // preloader
    //     .loadAssets()
    //     .then(() => timeout(1000))
    //     .then(() => preloader.leave())
    //     .then(() => {
    //         const animation = createInitialBaseTimeline();

    // const animation = createInitialBaseTimeline();

    // if (document.body.classList.contains('index-page')) {
    //     document.addEventListener(
    //         'main-sequence-appended',
    //         () => {
    //             preloader.init();
    //         },
    //         { once: true },
    //     );
    // } else {
    preloader.init();
    // }

    barba.init({
        transitions: [BaseTransition],
        views: [
            IndexPageView,
            // lazyView('index-page', () =>
            //     import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
            //         (m) => m.default,
            //     ),
            // ),
            lazyView('catalog-page', () =>
                import(/* webpackChunkName: "catalog-page", webpackPrefetch: true */ './views/catalog').then(
                    (m) => m.default,
                ),
            ),
            lazyView('catalog-detail-page', () =>
                import(/* webpackChunkName: "catalog-page", webpackPrefetch: true */ './views/catalog-detail').then(
                    (m) => m.default,
                ),
            ),
        ],
        prefetchIgnore: true,
        preventRunning: true,
        // prevent: ({ el }) => el.classList.contains('chps-content-list-pagination-link'),
        timeout: 5000,
        debug: NODE_ENV === 'development',
    });

    let playingVideos: HTMLVideoElement[] = [];

    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            playingVideos = Array.from(document.querySelectorAll('video')).filter((video) => !video.paused);
            playingVideos.forEach((video) => video.pause());
        } else {
            playingVideos.forEach((video) => video.play());
            playingVideos = [];
        }
    });

    // animation.play();

    // });

    if (NODE_ENV === 'development') {
        appQueue.pushTask(createGrid);
    }
});

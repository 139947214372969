import gsap from 'gsap';

let trigger: gsap.plugins.ScrollTriggerInstance | null;
let k = window.matchMedia('(min-width: 1024px)').matches ? 50 : 40;

function init(container: HTMLElement | Document = document) {
    // const blocks = Array.from(container.querySelectorAll('.js-parallax-block'));
    // blocks.forEach(block => {
    //     const contents = block.querySelectorAll('.js-parallax-content');
    //     contents.forEach(content => {
    //         gsap.to(content, {
    //             yPercent: k,
    //             scrollTrigger: {
    //                 trigger: block,
    //                 start: "bottom-=5% bottom",
    //                 end: "bottom top",
    //                 scrub: true,
    //             }
    //         });
    //     })
    // });

    const easyBlock = Array.from(container.querySelectorAll('.js-parallax-easy-block'));
    easyBlock.forEach(block => {
        const logo = block.querySelector('.js-parallax-easy-block-logo');
        const text = block.querySelector('.js-parallax-easy-block-text');
        const textSmall = block.querySelector('.js-parallax-easy-block-text-small');
        const iconBlock = block.querySelector('.js-parallax-easy-block-icon');

        if (logo && text) {
            gsap.timeline({
                scrollTrigger: {
                  trigger: block,
                  start: "center center",
                  end: "bottom top",
                  scrub: 1,
                  pin: true,
                }
              })
                .from(text,  { y: innerHeight * 0.5 })
                .from(textSmall, { y: innerHeight * 0.5 })
                .from(iconBlock, { opacity: 0, scale: 0.95 }, "+=0.3");
        }
    });

    window.addEventListener('resize', onResize);
}

function onResize() {
    k = window.matchMedia('(min-width: 1024px)').matches ? '20' : '0';
}

function destroy(container: HTMLElement | Document = document) {
    if (trigger) {
        trigger.kill();
        trigger = null;
    }

    window.removeEventListener('resize', onResize);
}

const _module = { init, destroy };

export default _module;

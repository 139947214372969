import './ImgSequence.scss';
import { LitElement, html, css, unsafeCSS } from 'lit';
import LazyLoad from 'vanilla-lazyload';
import _styles from './ImgSequence.ce.scss';

export interface ImgSequence {
    autoplay: boolean;
    fps: number;
    frames: number;
    lazy: boolean;
    ready: boolean;
    shouldRender: boolean;
    src: string;
    imgWidth: number;
    imgHeight: number;
    _animatedObserver: IntersectionObserver;
    _canvasWidth: number;
    _canvasHeight: number;
    _ctx: CanvasRenderingContext2D | null;
    _currentIndex: number;
    _images: HTMLImageElement[];
    _bitmaps: (ImageBitmap | HTMLImageElement)[];
    _interval: NodeJS.Timeout;
    _isPlaying: boolean;
    _ro?: ResizeObserver;
}

const dpr = Math.min(window.devicePixelRatio, 2);

const observer = new IntersectionObserver(
    (entries, obs) => {
        entries.forEach((entry) => {
            const target = entry.target as ImgSequence;

            if (entry.isIntersecting) {
                let loaded = 0;

                obs.unobserve(target);

                target._images.forEach((img) => {
                    LazyLoad.load(img, {
                        callback_loaded: (el) => {
                            loaded++;

                            if (loaded === target._images.length) {
                                target._fetchAndDecode();
                            }
                        },
                    });
                });
            }
        });
    },
    { rootMargin: '1000px 0px 1000px 0px' },
);

const animateObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const target = entry.target as ImgSequence;

        if (entry.isIntersecting) {
            if (target.autoplay) {
                target.play();
            }
        } else {
            target.pause();
        }
    });
});

/**
 * @attr {Number} fps - Количество кадров в секунду (с какой скоростью проигрывать анимацию).
 * @attr {Number} frames - Общее количество кадров.
 *
 * @slot - <img> or <picture>. Адаптивный спрайт секвенции.
 */
export class ImgSequence extends LitElement {
    constructor() {
        super();
        this._onDocumentVisibilityChange = this._onDocumentVisibilityChange.bind(this);

        this._animatedObserver = animateObserver;
        this._isPlaying = false;
        this.ready = false;
        this.shouldRender = true;
        this.imgWidth = 0;
        this.imgHeight = 0;

        if ('ResizeObserver' in window) {
            this._ro = new ResizeObserver(() => {
                this._setCanvasDimensions();

                if (this.ready) {
                    this._fetchAndDecode();
                }

                requestAnimationFrame(() => {
                    if (this._bitmaps) {
                        this.renderFrame(this._currentIndex, true);
                    }
                });
            });
        }
    }

    static get properties() {
        return {
            autoplay: {
                type: Boolean,
                value: false,
            },
            ready: {
                type: Boolean,
                value: false,
                reflect: true,
            },
            shouldRender: {
                type: Boolean,
                value: true,
            },
            fps: {
                type: Number,
                value: 25,
            },
            frames: {
                type: Number,
                value: 1,
            },
            imgWidth: {
                type: Number,
            },
            imgHeight: {
                type: Number,
            },
            lazy: {
                type: Boolean,
                value: false,
            },
            _currentIndex: {
                type: Number,
                value: 0,
            },
            _interval: {
                type: Number,
            },
            _canvasWidth: {
                type: Number,
            },
            _canvasHeight: {
                type: Number,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this._ro?.observe(this);
        document.addEventListener('visibilitychange', this._onDocumentVisibilityChange);

        setTimeout(() => {
            const canvas = this.renderRoot.querySelector('canvas');

            if (canvas) {
                this._ctx = canvas.getContext('2d');
            }

            this._images = Array.from(this.querySelectorAll<HTMLImageElement>('img'));
            this._bitmaps = new Array(this._images.length);

            if (this._images.length > 0) {
                if (this.lazy) {
                    observer.observe(this);
                } else {
                    this._fetchAndDecode();
                }
            }

            setTimeout(() => {
                if (this._images[0]) {
                    this.imgWidth = this._images[0].naturalWidth;
                    this.imgHeight = this._images[0].naturalHeight;
                }
            }, 1);
        }, 1);

        animateObserver.observe(this);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.pause();
        document.removeEventListener('visibilitychange', this._onDocumentVisibilityChange);

        if (this._ro) {
            this._ro.disconnect();
            this._ro = undefined;
        }

        observer.unobserve(this);
        animateObserver.unobserve(this);
    }

    renderFrame(frame: number, force = false) {
        if (force || this._currentIndex !== frame) {
            this._currentIndex = frame;
            const img = this._bitmaps[frame];

            if (this.shouldRender && this.ready && img && this._ctx) {
                this._ctx.clearRect(0, 0, this._canvasWidth, this._canvasHeight);
                this._ctx.drawImage(
                    img,
                    0,
                    0,
                    this.imgWidth,
                    this.imgHeight,
                    0,
                    0,
                    this._canvasWidth,
                    this._canvasHeight,
                );
            }
        }
    }

    play() {
        this._isPlaying = true;
        clearInterval(this._interval);
        this._interval = setInterval(
            () => this.renderFrame(this._currentIndex < this.frames - 1 ? this._currentIndex + 1 : 0),
            1000 / this.fps,
        );
    }

    pause() {
        this._isPlaying = false;
        clearInterval(this._interval);
    }

    reset() {
        this.pause();
        this._currentIndex = 0;
    }

    restart() {
        this.reset();
        this.play();
    }

    _setCanvasDimensions() {
        this._canvasWidth = this.offsetWidth * dpr;
        this._canvasHeight = this.offsetHeight * dpr;
    }

    _onDocumentVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this.pause();
        } else if (this._isPlaying) {
            this.play();
        }
    }

    _fetchAndDecode() {
        if ('createImageBitmap' in window) {
            const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
            // Decode image off main thread
            this._images.forEach((img, i) => {
                fetch(img.currentSrc)
                    .then((response) => response.blob())
                    .then((blob) => createImageBitmap(blob))
                    .then((resultImg) => {
                        if (window.location.pathname == '/') {
                            const percentProgress = Math.floor((i / this._images.length) * 100);
                            if (counter) counter.textContent = `${percentProgress}`;
                        }

                        this._bitmaps[i] = resultImg;

                        for (let j = 0; j < this._bitmaps.length; j++) {
                            if (typeof this._bitmaps[j] === 'undefined') {
                                return;
                            }
                        }

                        setTimeout(() => {
                            this.ready = true;

                            if (!this.autoplay) {
                                this.renderFrame(this._currentIndex || 0);
                            }
                        }, 1);

                        if (!this.ready) {
                            setTimeout(() => {
                                this.dispatchEvent(new Event('ready'));
                            }, 2);
                        }
                    });
            });
        } else {
            this._images.forEach((img, i) => {
                this._bitmaps[i] = img;
            });

            setTimeout(() => {
                this.ready = true;

                if (!this.autoplay) {
                    this.renderFrame(this._currentIndex || 0);
                }
            }, 1);

            if (!this.ready) {
                setTimeout(() => {
                    this.dispatchEvent(new Event('ready'));
                }, 2);
            }
        }
    }

    render() {
        return html`
            <div style="padding-top: ${(this.imgHeight / this.imgWidth) * 100}%;">
                <canvas width="${this._canvasWidth}" height="${this._canvasHeight}"></canvas>
            </div>
            ${this.ready ? html`` : html`<slot></slot>`}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-img-sequence': ImgSequence;
    }
}

import type { Swiper as SwiperType } from 'swiper';
import gsap from 'gsap';
import { fetchSwiper } from '../dynamic-modules';

const map = new WeakMap<Element, any>();
const header = document.querySelector<HTMLElement>('.js-header');

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-flavours-slider:not(.swiper-initialized)')).forEach(
        async (el) => {
            const section = el.closest<HTMLElement>('.js-flavours-section');
            const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));
            const colors = slides.map((slide) => slide.dataset.color!);
            const lines = section ? Array.from(section.querySelectorAll('.js-flavours-line')) : [];
            let touchX = 0;
            let content: Element | null;
            let setContentX: Function | null;

            function setColorByIndex(index: number) {
                if (section && colors[index]) {
                    section.style.setProperty('--theme-color', colors[index]);
                    section.style.setProperty('--flavour-color', colors[index]);
                    section.setAttribute('data-color-bg', colors[index]);
                }
            }

            function setContent(swiper: SwiperType) {
                content = swiper.slides[swiper.activeIndex].querySelector('.flavour-inner');
                setContentX = gsap.quickSetter(content, 'translateX', 'px');
            }

            function onTouchStart(event: TouchEvent) {
                touchX = event.touches[0].clientX;
            }

            function onTouchMove(event: TouchEvent) {
                setContentX?.((event.touches[0].clientX - touchX) * 0.1);
            }

            function onTouchEnd() {
                gsap.to(content, { duration: 0.3, x: 0, ease: 'power3.out', overwrite: true });
            }

            if (slides.length > 1) {
                const { Swiper, Lazy, Pagination } = await fetchSwiper();
                const ripple = section?.querySelector<HTMLElement>('.js-flavours-slider-ripple');

                function playSlide(swiper: SwiperType) {
                    swiper.disable();
                    const nextSlide = slides[swiper.activeIndex];
                    const color = nextSlide.getAttribute('data-color');

                    el.style.setProperty('--sign', (swiper as any).swipeDirection === 'prev' ? '-1' : '1');

                    if (ripple) {
                        ripple.hidden = false;
                        ripple.style.transition = 'none';
                        ripple.classList.remove('bottom-left');
                        ripple.classList.remove('bottom-right');
                        ripple.classList.add(
                            (swiper as any).swipeDirection === 'prev' ? 'bottom-left' : 'bottom-right',
                        );

                        const sectionRect = section?.getBoundingClientRect();

                        if (sectionRect) {
                            const d =
                                Math.sqrt(
                                    sectionRect.width * sectionRect.width + sectionRect.height * sectionRect.height,
                                ) * 2;
                            ripple.style.setProperty('--size', `${d}px`);
                        }

                        requestAnimationFrame(() => {
                            ripple.style.transition = '';
                            ripple.style.setProperty('--scale', '1');

                            if (color) {
                                ripple.style.backgroundColor = color;
                            }

                            if (
                                section &&
                                section.offsetTop <= window.pageYOffset + 50 &&
                                section.getBoundingClientRect().bottom > 0
                            ) {
                                setTimeout(() => {
                                    header?.style.setProperty('--header-color', color);
                                }, 200);
                            }

                            ripple.addEventListener(
                                'transitionend',
                                () => {
                                    setColorByIndex(swiper.realIndex);
                                    ripple.hidden = true;
                                    ripple.style.removeProperty('--scale');
                                    swiper.enable();

                                    gsap.fromTo(
                                        lines,
                                        { drawSVG: '0%' },
                                        { duration: 1, drawSVG: '100%', ease: 'power3.out', stagger: 0.15 },
                                    );
                                },
                                { once: true },
                            );
                        });
                    }
                }

                const slider = new Swiper(el, {
                    modules: [Lazy, Pagination],
                    slidesPerView: 1,
                    watchSlidesProgress: true,
                    virtualTranslate: true,
                    rewind: true,
                    followFinger: false,
                    threshold: 30,
                    touchAngle: 65,
                    pagination: {
                        el: '.js-slider-pagination',
                        type: 'bullets',
                        clickable: true,
                        renderBullet: (index, className) =>
                            `<button class="${className}" aria-label="Перейти на слайд ${index + 1}"></button>`,
                    },
                    speed: 0,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        checkInView: true,
                    },
                    on: {
                        init: (swiper) => {
                            setColorByIndex(swiper.realIndex);
                            setContent(swiper);
                            el.addEventListener('touchstart', onTouchStart);
                            el.addEventListener('touchmove', onTouchMove);
                            el.addEventListener('touchend', onTouchEnd);
                        },
                        slideChange: (swiper) => {
                            playSlide(swiper);
                            requestAnimationFrame(() => {
                                setContent(swiper);
                            });
                        },
                        destroy: () => {
                            el.removeEventListener('touchstart', onTouchStart);
                            el.removeEventListener('touchmove', onTouchMove);
                            el.removeEventListener('touchend', onTouchEnd);
                        },
                    },
                });
                map.set(el, slider);
            }
        },
    );
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-flavours-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;

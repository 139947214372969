import LazyLoad from 'vanilla-lazyload';
import type { Swiper as SwiperType } from 'swiper';
import delegate from 'delegate';
import gsap from 'gsap';
import { createNav, destroyNav } from '../components/base-sliders';
import { fetchSwiper } from '../dynamic-modules';
import { isElementInViewport } from '../utils/dom';

const loadPrevNextAmount = 5;
const map = new WeakMap<Element, { slider: SwiperType; delegation: any }>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-aroma-slider:not(.swiper-initialized)')).forEach(
        async (el) => {
            const section = el.closest<HTMLElement>('.js-slider-block');
            const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));
            let btnNext: HTMLElement | null | undefined, btnPrev: HTMLElement | null | undefined;

            if (slides.length > 1) {
                const { Swiper } = await fetchSwiper();
                const nav = el.closest('.js-slider-block')?.querySelector<HTMLElement>('.js-slider-nav');
                const aromaInputs = section
                    ? Array.from(section.querySelectorAll<HTMLInputElement>('input.js-aroma-filter-input'))
                    : [];
                let aromas: string[] = [];

                const lazyloadNearestImages = (swiper: SwiperType) => {
                    const newSlides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

                    for (let i = 1; i < loadPrevNextAmount + 1; i += 1) {
                        const nextImg = newSlides[swiper.activeIndex + i]?.querySelector<HTMLImageElement>('img.lazy');

                        if (nextImg && !nextImg.dataset.llStatus) {
                            LazyLoad.load(nextImg, {});
                        }
                    }
                };

                const slider = new Swiper(el, {
                    slidesPerView: 'auto',
                    threshold: 30,
                    touchAngle: 65,
                    on: {
                        beforeInit: (swiper) => {
                            createNav(swiper, nav);
                            btnNext = section?.querySelector('.js-slider-next');
                            btnPrev = section?.querySelector('.js-slider-prev');
                            btnPrev?.classList.add('is-disable');
                        },
                        init: (swiper) => {
                            // const currentAromas = aromaInputs
                            //     .filter((input) => input.checked)
                            //     .map((input) => input.value);
                            // if (currentAromas.length > 0) {
                            //     //
                            // }

                            lazyloadNearestImages(swiper);
                        },
                        slideChange: (swiper) => {
                            lazyloadNearestImages(swiper);

                            if (swiper.activeIndex == 0) {
                                btnPrev?.classList.add('is-disable');
                                btnNext?.classList.remove('is-disable');
                            } else if (swiper.progress == 1) {
                                btnNext?.classList.add('is-disable');
                                btnPrev?.classList.remove('is-disable');
                            } else {
                                btnPrev?.classList.remove('is-disable');
                                btnNext?.classList.remove('is-disable');
                            }
                        },
                        destroy: () => {
                            destroyNav(nav);
                        },
                    },
                });

                let leaveTween: gsap.core.Tween | null;
                let enterTween: gsap.core.Tween | null;

                const leaveSlides: () => Promise<void> = () => {
                    return new Promise((resolve) => {
                        const prevSlides = slides.filter((slide) => !slide.hidden && isElementInViewport(slide));

                        slider.disable();

                        if (!leaveTween) {
                            leaveTween = gsap.to(prevSlides, {
                                duration: 0.55,
                                opacity: 0,
                                x: 15,
                                ease: 'power3.in',
                                stagger: 0.1,
                                overwrite: true,
                                onComplete: () => {
                                    if (leaveTween) {
                                        leaveTween.kill();
                                        leaveTween = null;
                                    }

                                    resolve();
                                },
                            });
                        } else {
                            leaveTween.then(() => resolve());
                        }
                    });
                };

                const enterSlides = () => {
                    const activeSlides = Array.from(
                        aromas.length === 0
                            ? slides
                            : slides.filter((el) => !!el.dataset.aroma && aromas.includes(el.dataset.aroma)),
                    );

                    activeSlides.forEach((el) => {
                        gsap.set(el, { opacity: 0, x: -15 });
                        el.hidden = false;
                    });

                    slides
                        .filter((el) => !activeSlides.includes(el))
                        .forEach((el) => {
                            el.hidden = true;
                        });

                    enterTween = gsap.to(activeSlides, {
                        duration: 0.75,
                        x: 0,
                        opacity: 1,
                        stagger: 0.1,
                        ease: 'power3.out',
                        delay: 0.2,
                        onComplete: () => {
                            if (enterTween) {
                                enterTween.kill();
                                enterTween = null;
                            }
                        },
                    });

                    slider.enable();
                    slider.update();
                };

                const delegation = delegate(section || document, 'input.js-aroma-filter-input', 'change', () => {
                    aromas = aromaInputs.filter((input) => input.checked).map((input) => input.value);

                    if (enterTween) {
                        enterTween.kill();
                        enterTween = null;
                    }

                    if (!leaveTween) {
                        leaveSlides().then(enterSlides);
                    } else {
                        leaveTween.then(enterSlides);
                    }
                });

                map.set(el, { slider, delegation });
            }
        },
    );
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-aroma-slider')).forEach((el) => {
        const obj = map.get(el);

        if (obj) {
            if (obj.slider) {
                obj.slider.destroy();
            }

            if (obj.delegation) {
                obj.delegation.destroy();
            }

            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;

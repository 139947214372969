import Lazyload from 'vanilla-lazyload';

export const lazyload = new Lazyload({
    elements_selector: '.lazy',
    threshold: 900,
    // use_native: true,
});

export const videoLazyload = new Lazyload({
    elements_selector: '.video-lazy',
    threshold: 1200,
    // use_native: true,
});

const header = document.querySelector<HTMLElement>('.js-header');
const commonRootMargin = '-6% 0% -93.9% 0%';
const threshold = [0, 1];
const themes = ['light', 'dark', 'dark-transparent', 'red-transparent'];

const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        if (header && entry.isIntersecting) {
            const { theme, colorBg } = target.dataset;

            if (!theme) {
                themes.forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });
                header.style.setProperty('--header-color', '#fff');
                return;
            }

            themes
                .filter((item) => item !== theme)
                .forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });

            if (colorBg) {
                header.style.setProperty('--header-color', colorBg);
            } else {
                header.style.setProperty('--header-color', '#fff');
            }

            header.classList.add(`header-theme--${theme}`);
        }
    });
};

const map = new Map<HTMLElement, IntersectionObserver>();
const observersMap = new Map<string, IntersectionObserver>();

function createThemeObserver(rootMargin: string) {
    return new IntersectionObserver(callback, { rootMargin, threshold });
}

function init(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-header-theme-trigger')).forEach((el) => {
        const rootMargin = el.dataset.themeObserverMargin || commonRootMargin;
        const usedObserver = observersMap.get(rootMargin);
        const observer = usedObserver || createThemeObserver(rootMargin);
        observer.observe(el);

        if (!usedObserver) {
            map.set(el, observer);
            observersMap.set(rootMargin, observer);
        }
    });
}

function destroy(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-header-theme-trigger')).forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
            map.delete(el);
        }
    });

    // observersMap.clear();

    if (header) {
        themes.forEach((item) => {
            header.classList.remove(`header-theme--${item}`);
        });
    }
}

const _module = { init, destroy };

export default _module;

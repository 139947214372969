import gsap from 'gsap';
import Cookie from 'js-cookie';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

async function init(container: Element | Document = document) {
    const adultCheckEl = document.querySelector<LitPopupElement>('.js-adult-check-container');
    const adultCheckElInner = document.querySelector<LitPopupElement>('.js-adult-check-inner');
    const adultCheckbox = document.querySelector<HTMLInputElement>('.js-adult-checkbox');
    const adultCheckNoBtn = document.querySelector<HTMLElement>('.js-adult--no');
    const adultCheckYesBtn = document.querySelector<HTMLElement>('.js-adult--yes');
    const adultCheckQuestionText = document.querySelector<HTMLElement>('.js-adult-question-container');
    const adultCheckMessageText = document.querySelector<HTMLElement>('.js-adult-message-container');

    function removeContainer() {
        adultCheckEl?.remove();
    }

    if (Cookie.get('IS_ADULT') === 'true') {
        removeContainer();
    } else {
        document.body.classList.add('no-scroll');
        window.scrollTo({ top: 0, behavior: 'auto' });

        adultCheckNoBtn?.addEventListener('click', () => {
            adultCheckQuestionText?.classList.add('is-hide');
            setTimeout(() => {
                adultCheckMessageText?.classList.remove('is-hide');
            }, 400);
        });

        adultCheckbox?.addEventListener('change', () => {
            adultCheckbox.checked ? adultCheckbox.classList.remove('is-error') : '';
        });

        adultCheckYesBtn?.addEventListener('click', () => {
            if (adultCheckbox && !adultCheckbox.checked) {
                adultCheckbox.classList.add('is-error');
            } else {
                Cookie.set('IS_ADULT', 'true', { expires: 365 });
                document.body.classList.remove('no-scroll');
                const tl = gsap.timeline();

                tl.to(
                    adultCheckElInner,
                    {
                        duration: 0.3,
                        opacity: 0,
                    },
                    0.2,
                ).to(
                    adultCheckEl,
                    {
                        duration: 0.5,
                        yPercent: -101,
                        xPercent: 101,
                        scale: 0.8,
                        onComplete: () => {
                            setTimeout(() => {
                                removeContainer();
                            }, 300);
                        },
                    },
                    '-=0.2',
                );
            }
        });
    }
}

const _module = { init };

export default _module;

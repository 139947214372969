import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(
    DrawSVGPlugin,
);

function init(container: HTMLElement | Document = document) {
    const triggers = container.querySelectorAll<HTMLElement>('.js-line-draw-container');

    triggers.forEach(trigger => {
        if (trigger) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger,
                    start: 'top+=30% bottom',
                    invalidateOnRefresh: false,
                    onEnter: () => {
                        const lines = Array.from(trigger.querySelectorAll<HTMLElement>('.js-line-draw'));
                        gsap.fromTo(
                            lines,
                            { drawSVG: '0%' },
                            { duration: 1.4, drawSVG: '100%', ease: 'power3.out', stagger: 0.15, delay: 0.3 },
                        );
                    },
                },
            });
        }
    })

}

const _module = { init };

export default _module;

import { htmlToElement } from '../utils/dom';

function randomInt(min: number, max: number) {
    return Math.round(min - 0.5 + Math.random() * (max - min + 1));
}

const lines = [
    '<svg class="card-hover-svg card-hover-svg-1" width="389" height="401" viewBox="0 0 389 401" fill="none" xmlns="http://www.w3.org/2000/svg"><path style="--path-length:580.391845703125px;" class="card-hover-enter" d="M409 181C431.27 156.696 404.5 115.5 388 110C329.5 68.5 230.205 137.553 175.511 189.278L-58.2858 385.716" stroke="white" stroke-width="4" stroke-linecap="round"/><path style="--path-length:621.3671875px;" class="card-hover-enter" d="M84.6021 185.151L83.3241 183.612L83.2972 183.635L83.271 183.658L84.6021 185.151ZM461.432 77.8404C462.27 78.5596 463.533 78.4629 464.252 77.6245C464.971 76.7861 464.874 75.5235 464.036 74.8043L461.432 77.8404ZM-107.331 353.622C-108.155 354.358 -108.228 355.622 -107.493 356.446C-106.758 357.271 -105.493 357.343 -104.669 356.608L-107.331 353.622ZM85.8802 186.689C109.616 166.969 149.758 136.446 192.984 109.018C236.272 81.5496 282.412 57.3425 318.205 50.0301L317.405 46.1111C280.838 53.5813 234.169 78.1473 190.841 105.64C147.452 133.172 107.173 163.799 83.3241 183.612L85.8802 186.689ZM318.205 50.0301C389.906 35.3822 443.698 62.6289 461.432 77.8404L464.036 74.8043C445.45 58.8625 390.423 31.1939 317.405 46.1111L318.205 50.0301ZM83.271 183.658L-107.331 353.622L-104.669 356.608L85.9332 186.644L83.271 183.658Z" fill="white"/></svg>',
    '<svg class="card-hover-svg card-hover-svg-2" width="218" height="305" fill="none" xmlns="http://www.w3.org/2000/svg"><path style="--path-length:580.391845703125px;" class="card-hover-enter" d="M22.82 320.483c-26.896-55.69-46.397-190.034 87.671-291.205 49.913-37.5 139.396-40.603 229.654 35.948" stroke="#fff" stroke-width="6"/><path style="--path-length:621.3671875px;" class="card-hover-enter" d="M121.09 321C77.556 260.397 13.557 124.346 105.832 64.967c90-57.914 222.413 92.844 243.361 175.344" stroke="#fff" stroke-width="6"/></svg>',
    '<svg class="card-hover-svg card-hover-svg-3" width="389" height="226" viewBox="0 0 389 226" fill="none" xmlns="http://www.w3.org/2000/svg"><path style="--path-length:580.391845703125px;" class="card-hover-enter" d="M-2 81.5C25.3333 87.1667 95.3 93.1 156.5 71.5C233 44.5 334.5 24.5 344.5 63.5C354.5 102.5 281 153.5 281 227" stroke="white" stroke-width="4"/><path style="--path-length:621.3671875px;" class="card-hover-enter" d="M-4 47.5C1.83333 52.8333 25.5 61.3 73.5 52.5C133.5 41.5 283 -23.5 390.5 14.5" stroke="white" stroke-width="4"/></svg>',
    '<svg class="card-hover-svg card-hover-svg-4" width="389" height="210" viewBox="0 0 389 210" fill="none" xmlns="http://www.w3.org/2000/svg"><path style="--path-length:580.391845703125px;" class="card-hover-enter" d="M0 98.5C28.6667 96 98 86.4 146 68C206 45 266 70.5 266.5 103C267 135.5 271 149.5 208.5 214.5" stroke="white" stroke-width="4"/><path style="--path-length:621.3671875px;" class="card-hover-enter" d="M286.5 212.5C297 204.833 316.7 182.6 311.5 155C305 120.5 289 47 345 57.5C389.8 65.9 397 74 395 77" stroke="white" stroke-width="4"/><path style="--path-length:621.3671875px;" class="card-hover-enter" d="M-4 53.5C23.8333 53 105.4 44.4 209 14C312.6 -16.4 380.833 22.3333 402 45.5" stroke="white" stroke-width="4"/></svg>'
];

function onMouseenter(this: HTMLElement, event: Event) {
    const target = this;
    const currentAnimatingSvg = target.querySelector('.card-hover-svg.is-leaving');

    if (event.type === 'touchstart') {
        target.classList.add('is-touch');
    }

    if (event.type === 'mouseenter' && target.classList.contains('is-touch')) {
        return;
    }

    if (currentAnimatingSvg) {
        const prevOnAnimationEnd = (currentAnimatingSvg as any)._onAnimationEnd;

        const onAnimationEnd = () => {
            (currentAnimatingSvg as any)._onAnimationEnd = null;
            currentAnimatingSvg.remove();
            const svg = htmlToElement(lines[randomInt(0, lines.length - 1)]);
            target.prepend(svg);
        };

        if (prevOnAnimationEnd) {
            currentAnimatingSvg.removeEventListener('animationend', prevOnAnimationEnd);
        }
        currentAnimatingSvg.addEventListener('animationend', onAnimationEnd, { once: true });
        (currentAnimatingSvg as any)._onAnimationEnd = onAnimationEnd;
    } else {
        const svg = htmlToElement(lines[randomInt(0, lines.length - 1)]);
        target.prepend(svg);

        if (event.type === 'touchstart') {
            setTimeout(() => {
                target.classList.remove('is-touch');
                let boundFunc = onMouseleave.bind(target);
                boundFunc();
            }, 300);
        }
    }
}

function onMouseleave(this: HTMLElement) {
    const target = this;
    const svg = target.querySelector('.card-hover-svg');
    if (svg) {
        const prevOnAnimationEnd = (svg as any)._onAnimationEnd;
        if (prevOnAnimationEnd) {
            svg.removeEventListener('animationend', prevOnAnimationEnd);
        }
        svg.classList.add('is-leaving');
        svg.addEventListener(
            'animationend',
            () => {
                svg.remove();
            },
            { once: true },
        );
    }
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-card-hover')).forEach((el) => {
        el.addEventListener('mouseenter', onMouseenter);
        el.addEventListener('mouseleave', onMouseleave);
        el.addEventListener('touchstart', onMouseenter);
        el.addEventListener('contextmenu', onMouseleave);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-card-hover')).forEach((el) => {
        el.removeEventListener('mouseenter', onMouseenter);
        el.removeEventListener('mouseleave', onMouseleave);
        el.removeEventListener('touchstart', onMouseenter);
        el.addEventListener('contextmenu', onMouseleave);
    });
}

const _module = { init, destroy };

export default _module;

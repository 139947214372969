function setCursor(this: HTMLElement, event: MouseEvent) {
    const cursorPosition = (event.clientX / this.clientWidth) - 0.5;
    if (cursorPosition > 0) {
        this.classList.add('next-slide');
        this.classList.remove('prev-slide');
    } else {
        this.classList.add('prev-slide');
        this.classList.remove('next-slide');
    }
}

function changeSlide(this: HTMLElement, event: MouseEvent) {
    if (event.target.tagName !== "A" && event.target.tagName !== "BUTTON") {
        if (this.classList.contains('next-slide')) {
            this.swiper.slideNext();
        } else if (this.classList.contains('prev-slide')) {
            this.swiper.slidePrev();
        }
    }
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-slider-with-arrows')).forEach(
        (el) => {
            el.addEventListener('mousemove', setCursor);
            el.addEventListener('click', changeSlide);
        },
    );
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-slider-with-arrows')).forEach((el) => {
        el.removeEventListener('mousemove', setCursor);
        el.removeEventListener('click', changeSlide);
    });
}

const _module = { init, destroy };

export default _module;
